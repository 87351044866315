<template>
    <a-modal
            :title="title"
            :visible="visible"
            :confirm-loading="confirmLoading"
            @cancel="$emit('close')"
            @ok="handleSubmit"
            destroyOnClose
    >
        <a-form
                :form="form"
                v-bind="formItemLayout"
                v-if="visible"
        >
            <a-form-item label="类型" >
                <a-select v-decorator="['type', { rules: [{ required: true, message: '请选择类型!' }] }]" placeholder="请选择类型">
                    <a-select-option :value="item.id" v-for="(item,index) in options" :key="index">{{item.name}}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="学科" >
                <a-select v-decorator="['subjectid', { rules: [{ required: true, message: '请选择学科!' }] }]" placeholder="请选择学科">
                    <a-select-option :value="item.id" v-for="(item,index) in data" :key="index">{{item.name}}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="激活码批号">
                <a-input v-decorator="['spec', { rules: [{ required: true, message: '请输入生成的激活码批次!' }] }]" placeholder="请输入生成的激活码批次" />
            </a-form-item>
            <a-form-item label="激活码前缀">
                <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入生成激活码前缀!' }] }]" placeholder="请输入生成激活码前缀" />
            </a-form-item>
            <a-form-item label="激活码数量">
                <a-input v-decorator="['number', { rules: [{ required: true, message: '请输入生成激活码数量!' }] }]" placeholder="请输入生成激活码数量" />
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
    import { uploadImg } from '@/libs/api'
    import * as http from '@/libs/analysis'
    export default {
        name: 'addActive',
        props: [ 'visible', 'data' ],
        beforeCreate() {
            this.form = this.$form.createForm(this);
        },
        watch:{
            visible(val){
                if(val){
                    if(this.data){
                        this.title = "编辑激活码"
                    }else{
                        this.title = "新增激活码";
                    }
                }
            }
        },
        data(){
            return{
                confirmLoading: false,
                loading: false,
                id: 1,
                title: '',
                spec:'',
                name:'',
                days:'',
                number:'',
                formItemLayout: {
                    labelCol: { span: 5 },
                    wrapperCol: { span: 18 },
                },
                options :[
                    {
                        name: '含资料',
                        id:1
                    },{
                        name:'不含资料',
                        id:2
                    }
                ]
            }
        },
        methods:{
            handleSubmit(){
                this.form.validateFields((err, values) => {
                    if(err) return;
                    this.confirmLoading = true
                    http.create_active(values).then(res=>{
                        this.$emit('close')
                        this.$emit('update')
                        this.confirmLoading = false
                    }).catch((error)=>{
                        console.log(error)
                        this.confirmLoading = false
                    })
                });
            },
            beforeUpload(){

            },
            customRequest(data){
                let formData = new FormData()
                formData.append('file',data.file)
                uploadImg(formData).then(res=>{
                    if(res){
                        this.imageUrl=res.fileUrl
                    }
                },error=>{
                    console.log(error)
                })
            },
            handleChange(){

            }
        }
    }
</script>
