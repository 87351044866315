<template>
    <div class="pd20x flex1">
        <addActive :visible="visible" @close="visible = false" :data="subjectList" @update="getData"></addActive>
        <div class="contentBox h100pct ofA">
            <div class="filter" style="padding:10px 20px">
                <div>
                    <label>搜索类型：</label>
                    <a-select v-model="type" style="width: 120px" @change="onChange" class="mr10px" placeholder="请选择搜索类型">
                        <a-select-option value="all" selected>
                            全部类型
                        </a-select-option>
                        <a-select-option value="contain">
                            含资料
                        </a-select-option>
                        <a-select-option value="nocontain">
                            不含资料
                        </a-select-option>
                    </a-select>
                    <a-input-search placeholder="请输入搜索的内容" v-model="searchInput" style="width: 300px" @search="onSearch" class="mr10px"/>
                </div>
                <div>
                    <a-button type="primary" @click="()=>{ currentItem = null ; visible = true }" style="margin-right: 20px">添加</a-button>
                    <a-button type="info" @click="exportText">展示可用码</a-button>
                </div>
            </div>
            <a-table
                    :columns="columns"
                    :data-source="data"
                    :rowKey="'uid'"
                    :pagination="{pageSize: page.pageSize, total}"
                    @change="changePage"
            >
                <template slot="codeNumber" slot-scope="text">
                    {{text}}
                </template>
                <template slot="activeUserInfo" slot-scope="text, record">
                    <div>
                        <img :src="record.headimgurl" style="border-radius: 50%;width:35px">
                        {{record.nickname}}
                    </div>
                </template>
                <template slot="statusSwitch" slot-scope="text, record">
                    <a-switch :checked="record.status==2?false:true" checked-children="开" un-checked-children="关" @change="opcl(record.id)"/>
                </template>
                <template slot="activeTimeBag" slot-scope="text, record">
                    <div v-if="record.activeTime>0">{{formatDate(record.activeTime*1000)}}</div>
                </template>
                <template slot="operation" slot-scope="text, record">
                    <a-space style="margin-right: 10px">
                        <a-button @click="copyOne(record.codeNumber)">复制</a-button>
                    </a-space>
                    <a-space style="margin-right: 10px">
                        <a-button @click="bmessage(record.id)">备注</a-button>
                    </a-space>
                    <a-space>
                        <a-button @click="deleteItem(record.id)">删除</a-button>
                    </a-space>
                </template>
            </a-table>
            <a-modal
                    v-model="showCodes"
                    :title="ModalTitle"
                    ok-text="点击复制"
                    cancel-text="关闭"
                    @ok="handleOk"
                    @cancel="handleCancel"
            >
                <p>{{ ModalText }}</p>
            </a-modal>
            <a-modal v-model="messageModel" title="备注信息" ok-text="确认" cancel-text="取消" @ok="confirmMessage" @cancel="hideMessage">
                <a-input v-model="message"  placeholder="输入备注信息" />
            </a-modal>
        </div>
    </div>
</template>
<script>
    const columns = [
        { title: '类型', dataIndex: 'type', align: 'center' },
        { title: '学科', dataIndex: 'subject_name', align: 'center' },
        { title: '批次', dataIndex: 'spec', align: 'center'},
        { title: '编号', dataIndex: 'codeNumber', align: 'center'},
        { title: '激活用户', dataIndex: 'activeUser',scopedSlots: { customRender: 'activeUserInfo' }, align: 'center' },
        { title: '使用时间', dataIndex: 'activeTime',scopedSlots: { customRender: 'activeTimeBag' }, align: 'center' },
        { title: '状态', dataIndex: 'status',scopedSlots: { customRender: 'statusSwitch' }, align: 'center' },
        { title: '备注信息', dataIndex: 'message', align: 'center'},
        { title: '操作', dataIndex: 'operation', scopedSlots: { customRender: 'operation' }, align: 'center' }
    ];
    import {formatDate} from '@/libs/moment'
    import * as http from '@/libs/analysis'
    import addActive from '@/components/analysis/addActive'
    export default {
        name: 'activeList',
        components: { addActive },
        data(){
            return{
                type : 'all',
                showCodes : false,
                ModalTitle : '',
                ModalText : '',
                data:[],
                columns,
                searchInput: '',
                visible : false,
                currentItem: null,
                filter:{},
                page:{
                    pageSize: 15,
                    page: 1
                },
                subjectList : [],
                total: 0,
                messageModel: false,
                messageId: '',
                message : ''
            }
        },
        mounted(){
            this.$nextTick(()=>{
                Promise.all([this.getAllSubject()]).then(res=>{
                    this.getData()
                })
            })
        },
        methods:{
            formatDate,
            bmessage(id){
                this.messageId = id;
                this.messageModel = true;
            },
            opcl(id){
                http.change_active_status({id}).then(res => {
                    this.getData();
                })
            },
            onChange(){

            },
            copyOne(text){
                this.$copyText(text).then(res=> {
                    this.$message.success('复制成功')
                },error=>{
                    this.$message.success(error)
                    console.log(error)
                })
            },
            handleOk(){
                this.$copyText(this.ModalText).then(res=> {
                    this.$message.success('复制成功')
                },error=>{
                    this.$message.success(error)
                    console.log(error)
                })
            },
            handleCancel(){
                this.showCodes = false
            },
            confirmMessage(){
                http.active_message({id:this.messageId,message:this.message}).then(res => {
                    this.messageId = '';
                    this.message = '';
                    this.messageModel = false;
                    this.getData()
                })
            },
            hideMessage(){
                this.messageModel = false
            },
            exportText() {
                let filter = {};
                filter['searchInput'] = this.searchInput
                filter['type'] = this.type
                http.get_active_show({...filter}).then(res => {
                    if(res.data){
                        this.showCodes = true;
                        this.ModalText = res.data;
                        this.ModalTitle = '可用激活码 ('+res.count+')';
                    }else{
                        this.$message.warn('没有可用的激活码')
                    }
                })
            },
            getAllSubject(){
                return new Promise((resolve, reject)=>{
                    http.get_allSubject().then(res=>{
                        this.subjectList =  res
                        resolve()
                    }).catch(error=>{
                        reject()
                    })
                })
            },
            getData(){
                http.get_active_list({...this.page, ...this.filter}).then(res => {
                    let {total, data} = res;
                    this.data = data
                    this.total = total
                })
            },
            deleteItem(id){
                let _this = this
                this.$confirm({
                    title: '是否确定删除该条信息?',
                    centered: true,
                    onOk() {
                        http.del_active({id:id}).then(res => {
                            _this.$message.info('删除成功');
                            _this.getData()
                        })
                    },
                });
            },
            onSearch(){
                this.filter = {};
                this.filter['searchInput'] = this.searchInput
                this.filter['type'] = this.type
                this.getData()
            },
            changePage({current}){
                this.page.page = current
                this.getData()
            }
        }
    }
</script>
